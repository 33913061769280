<template>
  <div style="text-align: center; background-color: white">
    <van-image class="" :src="require('@/assets/common/payment_complated.png')" width="30%" height="20%" />
    <div id="close">
      <p>本页面在{{ count }}秒后自动关闭</p>
      <button @click="closePage()">关闭</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "StripeNoticePage",
  data() {
    return {
      count: 0,
      timer: false
    }

  },
  created() {
    this.clickJump();
  },
  methods: {
    closePage() {
      console.log("跳转url_type：" + this.$route.query.url_type);
      let url_type = this.$route.query.url_type;
      let url = "https://wx.playhy.com/#/";
      switch (url_type) {
        case "dajin":
          url += "dajin";
          break;
        case "rxhj":
          url += "rxhj";
          break;

        case "rxdldH5":
          url += "rxdldH5";
          break;
        case "rxdld":
          url += "cn/rxdld";
          break;
        case "egdld":
          url += "eg/rxdld";
          break;
        case "icelord":
          url += "icelord";
          break;
        case "credit":
          url += "credit";
          break;
        case "darkxiyou":
          url += "darkxiyou";
          break;
        case "ghlcity":
          url += "ghlcity";
          break;
        case "fkjlb":
          url += "fkjlb";
          break;
        case "sgtl":
          url += "sgtl";
          break;
        case "smzb":
          url += "smzb";
          break;
        case 'api_credit':
          url = 'http://api.mfms.xhppgames.com/h5/#/credit'
          break;
        case 'chiyou_h5':
          url = 'https://api.sqxh.playhy.com/#/play?gid=bb539f2d4aae41839f043a5ed1927300&login=1'
          break;
        case 'darkxiyou_h5':
          url = 'https://api-pro.darkxiyou.com/#/play?gid=8bdc66d69b46489c8ae171ace27b1074&login=1&pay_login=1'
          break;
        case 'darkxiyou_test_h5':
          url = 'https://api.darkxiyou.com/#/play?gid=8bdc66d69b46489c8ae171ace27b1074&login=1&pay_login=1'
          break;
        case 'legendary_overlord_ios':
          url = 'https://api.legendoflord.com//#/play?gid=a3d3d24157074c86b130bfa299299203&login=1&pay_login=1'
          break;
        case 'legendary_overlord_android':
          url = 'https://api.legendoflord.com//#/play?gid=bbd2ca60a6c044e59d1ce10464b796b7&login=1&pay_login=1'
          break;
        case 'thunderword_h5':
          url = 'https://api-lt.darkxiyou.com/#/play?gid=a895b258073f44bb813e34f533422f17&login=1&pay_login=1'
          break;
        case 'dashencj_h5':
          url = 'https://api-lt.kingverdict.com/#/play?gid=2527c95136f64d178957b30d5b5a9501&login=1&pay_login=1'
          break;
        case 'fkjlb_h5':
          url = 'https://fkjlb.clubtreasurebox.com/#/play?gid=97ea578eb97a4341bf77f45a3b224985'
          break;
        case 'secret':
          url = 'https://wx.playhy.com/#/secret'
          break;
        case 'role':
          url = 'https://wx.playhy.com/#/role'
          break;
        case 'challenge':
          url = 'https://wx.playhy.com/#/challenge'
          break;
        case 'ios_android':
          url = ''
          break;
      }
      window.opener = null;
      window.open(url, "_top").close()
      window.opener.location.reload()
    },
    //几秒后进入跳转页面

    clickJump() {
      const timejump = 3;

      if (!this.timer) {
        this.count = timejump;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= timejump) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.closePage();

          }

        }, 1000)

      }

    },


  },
  beforeDestroy() {    //页面关闭时清除定时器

    clearInterval(this.timer);
  },
}
</script>

<style scoped>
#close {
  font-size: 16px;
  text-align: center;
}
</style>
